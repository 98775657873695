// input[type=date]::-webkit-calendar-picker-indicator {
// 	color: #999;
// 	height: 8px;
// 	position: relative;
// 	width: 8px;
// 	z-index: 0;

// }
// input[type=date]::-webkit-calendar-picker-indicator:before {
// 	background: #eee;
// 	background: linear-gradient(90deg, #eee, #fff);
// 	box-shadow: 0 1px 1px rgba(0,0,0,0.1);
// 	border-radius: 24px;
// 	content: '';
// 	display: block;
// 	height: 22px;
// 	left: -2px;
// 	position: absolute;
// 	top: -5px;
// 	width: 22px;
// 	z-index: -1;
// }
// input[type=date]::-webkit-calendar-picker-indicator:hover {
// 	background: none;
// }
// input[type=date]:focus {
// 	border-color: #3399ff;
// }

.prayer-times {
  &__swiper-wrapper {
    padding-bottom: 50px;

    @media screen and (min-width: 992px) {
      padding-bottom: 0;
    }
  }
  &__item {
  }
  &__item-content {
  }
  &__item-date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #ffffff;
  }
  &__item-head {
    background: linear-gradient(240.1deg, #f0af2a -4.82%, #e97221 69.18%, #ff4228 131.79%);
    border-radius: 4px 4px 0px 0px;
    min-height: 96px;
  }
  &__item-title {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #5d5d5d;
  }
  &__row {
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    padding: 14px 20px;

    &:nth-child(even) {
      background: #f9f9f9;
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
  &__value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  &__item-wrapper {
    &:nth-child(2) {
      .prayer-times__item-head {
        background: linear-gradient(240.1deg, #16a9db -4.82%, #126998 69.18%, #0d3461 131.79%);
      }
    }
    &:nth-child(3) {
      .prayer-times__item-head {
        background: linear-gradient(240.1deg, #b549e7 -4.82%, #801ba0 69.18%, #92669b 131.79%);
      }
    }
    &:nth-child(4) {
      .prayer-times__item-head {
        background: linear-gradient(240.1deg, #ef9b93 -4.82%, #f25c70 50.37%, #f42551 131.79%);
      }
    }
  }
}

.prayer-map {
  @media screen and (max-width: 991px) {
    iframe {
      max-height: 173px;
    }
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
      font-size: 25px;
      line-height: 30px;
    }
  }
  &__select {
    position: relative;
    select {
      padding: 12px 16px;
      min-width: 234px;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #808080;
    }
  }
  &__appearance {
    font-size: 10px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
    pointer-events: none;
  }

  &__selectable {
    padding: 22px;
    border: 1px solid #ececec;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    max-width: 390px;
  }
}

.prayer-detail {
  &__label {
    font-style: normal;
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    line-height: 28px;

    @media screen and (min-width: 992px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  &__select {
    position: relative;
    width: 50%;
    margin-left: 10px;

    @media screen and (min-width: 992px) {
      margin-left: 0;
      width: 234px;
    }

    select {
      padding: 12px 16px;
      border: 1px solid #ededed;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #808080;
      width: 100%;
    }
  }
  &__appearance {
    font-size: 10px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;
    pointer-events: none;
  }
  &__appearance {
  }
  &__city {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;

    color: #FFFFFF;
  }
  &__countdown {
    margin-bottom: 10px;
    text-align: center;

    @media screen and (min-width: 992px) {
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__countdown-date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-align: center;

    @media screen and (min-width: 992px) {
      text-align: right;
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__countdown-label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  &__date {
  }
  &__date-item {
    height: 100%;

    @media screen and (min-width: 992px) {
      width: 70px;
    }
  }
  &__date-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    @media screen and (max-width: 991px) {
      width: 100px;
      text-align: right;
    }
  }

  &__date-sp {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }

  &__date-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 991px) {
      width: 100px;
      text-align: left;
    }
  }
  &__datetime {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
  }
  &__filter {
    padding: 0 9px 0 14px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 50%;
    padding-right: 10px;

    @media screen and (min-width: 992px) {
      width: 234px;
    }
  }
  &__filter-control {
  }
  &__filter-label {
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #808080;
    border: 0;

    &::-webkit-input-placeholder { /* Edge */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #808080;
      border: 0;
    }

    &:-ms-input-placeholder { /* Internet Explorer */
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #808080;
      border: 0;
    }

    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: #808080;
      border: 0;
    }
  }
  &__info {
    @media screen and (min-width: 992px) {
      width: 200px;
      min-width: 200px;
    }
  }
  &__next {
    line-height: 100%;
    &.active {
      color: #000000;
    }
  }
  &__prev {
    margin-left: 20px;
    line-height: 100%;
    &.active {
      color: #000000;
    }
  }

  &__summary {
    min-height: 96px;
    background: linear-gradient(240.1deg, #F0AF2A -4.82%, #E97221 69.18%, #FF4228 131.79%);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 20px;

    @media screen and (min-width: 992px) {
      padding: 0 30px;
      border-radius: 4px 4px 0px 0px;
      margin-bottom: 0;
    }
  }
  &__table-wrapper {
    overflow: auto;
    max-width: 100%;
  }

  &__table {
    width: 100%;
    border-spacing: 0;
    border: 1px solid #ECECEC;
    margin-bottom: 30px;

    @media screen and (max-width: 991px) {
      min-width: 600px;
    }

    &--round {
      border-radius: 4px;
    }

    &--wide {
      min-width: unset;
    }

    th, td {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      padding: 15px 0;
    }

    tbody {
      tr {
        th {
          border-bottom: 1px solid #ECECEC;
        }
        &:nth-child(even) {
          background: #F9F9F9;
          td {
            border-top: 1px solid #ECECEC;
            border-bottom: 1px solid #ECECEC;
          }
        }
        &:last-child {
          td {
            border-bottom: 1px solid #ECECEC;
          }
        }
      }
    }
  }
}
